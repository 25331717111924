<template>
  <div class="deposit_edit world">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="paddB10">
      <el-breadcrumb-item :to="{ path: '/enterprise/centre/lanzhou/deposit/edit' }" class="bs_text_oo">
        返回上一级<span> | </span>残保金审核
      </el-breadcrumb-item>
      <el-breadcrumb-item class="color66">新增</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content backgroundWhite">
      <h1 class="font18 bold">按比例安置残疾人就业保障金年审申请</h1>
      <ul class="tab flex">
        <li v-for="(n,i) in tabs" :key="i" :class="i === 2?'active':''">{{ n }}</li>
      </ul>
      <div class="container next_three">
        <div class="tip marT50 marB20">
          <p>说明：1、请上传本单位上一年度1、6、12月三个月的全员工资表的扫描件或照片。</p>
          <p> 2、工资表所有扫描件或照片需加盖单位公章。</p>
        </div>
        <el-form :model="salaryForm" :rules="rules" ref="salaryForm" label-width="120px" class="demo-form-inline">
          <el-form-item label="一月份" prop="january" ref="yyfs">
            <el-upload
                action="/api/app-jycy-disabled/uploadImg"
                class="avatar-uploader avatar-uploader_certificate"
                :headers="uploadHeaders"
                list-type="picture-card"
                :limit="10"
                :on-remove="(file)=>{removeFile(file,'one')}"
                :file-list="januarys"
                :on-success="(res)=>{handleCertificateSuccess(res,'one')}"
                :before-upload="beforeAvatarUpload">
              <img src="~/static/center/img_add.png" alt="" class="block_inline img">
            </el-upload>
          </el-form-item>
          <el-form-item label="六月份" prop="june" ref="lyf">
            <el-upload
                action="/api/app-jycy-disabled/uploadImg"
                class="avatar-uploader avatar-uploader_certificate"
                :headers="uploadHeaders"
                list-type="picture-card"
                :limit="10"
                :file-list="junes"
                :on-remove="(file)=>{removeFile(file,'two')}"
                :on-success="(res)=>{handleCertificateSuccess(res,'two')}"
                accept=".jpg,.png"
                :before-upload="beforeAvatarUpload">
              <img src="~/static/center/img_add.png" alt="" class="block_inline img">
            </el-upload>
          </el-form-item>
          <el-form-item label="十二月份" prop="december" ref="seyf">
            <el-upload
                action="/api/app-jycy-disabled/uploadImg"
                class="avatar-uploader avatar-uploader_certificate"
                :headers="uploadHeaders"
                list-type="picture-card"
                :limit="10"
                :file-list="decembers"
                :on-remove="(file)=>{removeFile(file,'three')}"
                :on-success="(res)=>{handleCertificateSuccess(res,'three')}"
                accept=".jpg,.png"
                :before-upload="beforeAvatarUpload">
              <img src="~/static/center/img_add.png" alt="" class="block_inline img">
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="buttons_add">
          <el-button type="primary" class="w170" @click="goRouter('/enterprise/centre/lanzhou/deposit/nextEdit')">上一步
          </el-button>
          <el-button type="primary" class="w170" :loading="loading" @click="submit">下一步</el-button>
          <div class="tips">
            点击保存，信息会存为草稿，提交前均可编辑，确保信息无误后再提交。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nextEdit1",
  data() {
    return {
      tabs: ['单位信息', '安置残疾人就业信息', '全员工资表', '就业统计表', '社保缴费证明', '医保缴费证明'],
      salaryForm: {
        id: '',
        january: [],
        june: [],
        december: [],
      },
      januarys: [],
      junes: [],
      decembers: [],
      rules: {
        january: [
          {required: true, message: '请上传一月份工资表', trigger: 'change'}
        ],
        june: [
          {required: true, message: '请上传六月份工资表', trigger: 'change'}
        ],
        december: [
          {required: true, message: '请上传十二月份工资表', trigger: 'change'}
        ],
      },
      loading:false,
    }
  },
  methods: {
    async info() {
      let res = await this.$api.lanzhouGetAllSalaryApi();
      if (res.data.success && res.data.data) {
        let data = res.data.data;
        this.salaryForm.id = data.id;
        this.salaryForm.january = data.january.split(";");//将字符串转化为数组
        this.januarys = [];
        for (var i = 0; i <= this.salaryForm.january.length - 1; i++) {
          const ep = {};
          ep.url = this.salaryForm.january[i];

          this.januarys.push(ep);
        }
        this.salaryForm.june = data.june.split(";");
        this.junes = [];
        for (var i = 0; i <= this.salaryForm.june.length - 1; i++) {
          const ep = {};
          ep.url = this.salaryForm.june[i];
          this.junes.push(ep);
        }
        this.salaryForm.december = data.december.split(";");
        this.decembers = [];
        for (var i = 0; i <= this.salaryForm.december.length - 1; i++) {
          const ep = {};
          ep.url = this.salaryForm.december[i];
          this.decembers.push(ep);
        }
      }
    },
    submit() {
      this.$refs["salaryForm"].validate(valid => {
        if (valid) {
          this.salaryForm.january = this.salaryForm.january.join(";");
          this.salaryForm.june = this.salaryForm.june.join(";");
          this.salaryForm.december = this.salaryForm.december.join(";");
          this.loading = true;
          this.$api.lanzhouUpdateSalaryApi(this.salaryForm).then(res => {
            if (res.data.success) {
              this.loading = false;
              this.$message.success('保存成功！');
              this.goRouter('/enterprise/centre/lanzhou/deposit/nextEdit2');
            }else{
              this.loading = false;
            }
          })
        }
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M
    },
    removeFile: function (file, name) {
      if (name == 'one') {
        if (file.response) {
          this.salaryForm.january.forEach((item, i) => {
            if (item == file.response.msg) {
              this.salaryForm.january.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
            }
          })
        } else {
          this.salaryForm.january.forEach((item, i) => {
            if (item == file.url) {
              this.salaryForm.january.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
            }
          })
        }
      } else if (name == 'two') {
        if (file.response) {
          this.salaryForm.june.forEach((item, i) => {
            if (item == file.response.msg) {
              this.salaryForm.june.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
            }
          })
        } else {
          this.salaryForm.june.forEach((item, i) => {
            if (item == file.url) {
              this.salaryForm.june.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
            }
          })
        }
      } else if (name == 'three') {
        if (file.response) {
          this.salaryForm.december.forEach((item, i) => {
            if (item == file.response.msg) {
              this.salaryForm.december.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
            }
          })
        } else {
          this.salaryForm.december.forEach((item, i) => {
            if (item == file.url) {
              this.salaryForm.december.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
            }
          })
        }
      }
    },
    handleCertificateSuccess(res, index) {
      if (index == 'one') {
        this.salaryForm.january.push(res.msg);
        console.log(this.january);
      } else if (index == 'two') {
        this.salaryForm.june.push(res.msg);
      } else if (index == 'three') {
        this.salaryForm.december.push(res.msg);
      }
    },
    goRouter(url) {
      this.$router.push(url);
    },
  },
  created() {
    this.info();
  }
}
</script>

<style scoped lang="less">
@import "~assets/lanzhou/enterprise.less";
@import "~assets/lanzhou/deposit.less";
</style>
